import { combineReducers } from 'redux'
import { accessRequest } from './request'



const rootReducer = combineReducers({
    accessRequest
})

export default rootReducer
