export const REQUEST_ACCESS = 'REQUEST_ACCESS'
export const ACCESS_REQUESTED = 'ACCESS_REQUESTED'
export const UPDATE_RECEIVED = 'UPDATE_RECEIVED'


function _requestAccess() {
    return {
      type: REQUEST_ACCESS
    }
  }
  
function accessRequested(success, error) {
  return {
    type: ACCESS_REQUESTED,
    result: {
      success: success,
      error : error
      }
  }
}

function updateReceived(state){
  return {
    type: UPDATE_RECEIVED,
    result: {
      state: state
      }
  }
}

function performUpdate(site, mac, dispatch){
  return dispatch => {
    setTimeout(() => {
      fetch(`https://portal.zorrillamedia.de/api/state?site=${site}&mac=${mac}`, {method: 'GET'})
      .then(response => response.json())
      .then(response => {
        let status = response.status;
          dispatch(updateReceived(status)); // 0 -> unkown, 1 -> waiting, 2 -> success, 3 -> fuck you
          if(status < 2){
            dispatch(performUpdate(site, mac, dispatch));
          }
      })
      .catch(error => {
        console.log(error)
        dispatch(updateReceived("error"))
      });
    }, 1000);
  }
}

  
export function requestAccess(site, mac, name){
  return dispatch => {
      dispatch(_requestAccess())
      fetch('https://portal.zorrillamedia.de/api/request_access', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ 
          mac: mac,
          site: site,
          name: name
        })
      }).then(() => {
        dispatch(accessRequested(true));
        dispatch(performUpdate(site, mac, dispatch));
      })
        .catch((error) => dispatch(accessRequested(false, error)))
  }
}
