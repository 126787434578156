import {REQUEST_ACCESS, ACCESS_REQUESTED, UPDATE_RECEIVED} from '../actions'

const initialAccessRequestState = {
  isRequested: false,
  isSent: false,
  success: false,
  result: 0,
  error: {}
}

export function accessRequest(
    state = initialAccessRequestState,
    action
  ) {
    switch (action.type) {
      case REQUEST_ACCESS:
        return Object.assign({}, state, {
          isRequested: true
        })
      case ACCESS_REQUESTED:
        return Object.assign({}, state, {
          isRequested: true,
          isSent: true,
          success: action.result.success,
          error: action.result.error,
          result: action.result.success ? 1 : 0
        })
      case UPDATE_RECEIVED:
        return Object.assign({}, state, {
          result: action.result.state
        })
      default:
        return state
    }
  }

